
export const LANGUAGES = {
    none: "none",
    english: "english",
    nepali: "nepali",
    hindi: "hindi",
    japanese: "japanese"
};


export const BREATHING_MODES = {
    normal: "normal",
    calm: "calm",
};
export const BREATHING_MODES_HALFTIMES = {
    [BREATHING_MODES.normal]: 0.5,
    [BREATHING_MODES.calm]: 0.6,
};

export const DIRECTION = {
    in:"in",
    out:"out",
}
export const BREATHING_TEXT_MAP = {
    [LANGUAGES.english]: {
        [DIRECTION.in]: "in",
        [DIRECTION.out]: "out",
    },
    [LANGUAGES.nepali]: {
        [DIRECTION.in]: "मा",
        [DIRECTION.out]: "बाहिर"
    },
    [LANGUAGES.hindi]: {
        [DIRECTION.in]: "में",
        [DIRECTION.out]: "बाहर"
    },
    [LANGUAGES.japanese]: {
        [DIRECTION.in]: "に",
        [DIRECTION.out]: "でる"
    }
};

