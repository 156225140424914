import React, { PureComponent } from 'react';
import { FormControlLabel, Radio, RadioGroup, Grid, Tooltip } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import ContactSupport from '@material-ui/icons/ContactSupport';
import LineWeight from '@material-ui/icons/LineWeight';
import Brightness2 from '@material-ui/icons/Brightness2';
import DirectionsRun from '@material-ui/icons/DirectionsRun';
import Palette from '@material-ui/icons/Palette';
import Translate from '@material-ui/icons/Translate';
import SaveAlt from '@material-ui/icons/SaveAlt';
import { LANGUAGES, BREATHING_MODES } from './constants';
import { AppDialog } from './AppDialog'
import Button from '@material-ui/core/Button';

export class Settings extends PureComponent {
  render() {
    return (
      <div className="settings-container ">

        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="Mode">
              <Palette />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <RadioGroup aria-label="modes" name="modes" row={true}
              value={this.props.mode}
              onChange={this.props.updateSettings.bind(this, "mode")}
            >
              {Object.keys(BREATHING_MODES).map((mode) => (
                <FormControlLabel
                  key={mode}
                  value={mode}
                  control={<Radio color="primary" />}
                  label={mode}
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>

        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="guide">
              <Translate />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <RadioGroup aria-label="text" name="text" row={true}
              value={this.props.language}
              onChange={this.props.updateSettings.bind(this, "language")}
            >
              {Object.keys(LANGUAGES).map((language) => (
                <FormControlLabel
                  key={language}
                  value={language}
                  control={<Radio color="primary" />}
                  label={language}
                  labelPlacement="bottom"
                />
              ))}
            </RadioGroup>
          </Grid>
        </Grid>



        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="Duration">
              <DirectionsRun />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <Slider min={6000} max={30000} step={1000}
              value={this.props.duration}
              onChange={this.props.updateSettings.bind(this, "duration")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="Brightness">
              <Brightness2 />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <Slider min={1} max={255} step={5}
              value={this.props.brightness}
              onChange={this.props.updateSettings.bind(this, "brightness")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="Weight">
              <LineWeight />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <Slider min={1} max={25} step={1} classes={{ track: "track-thick" }}
              value={this.props.thickness}
              onChange={this.props.updateSettings.bind(this, "thickness")}
            />
          </Grid>
        </Grid>

        <Grid container spacing={32} className="settings-row" alignItems="center">
          <Grid item xs={2} sm={1}  >
            <Tooltip title="Feedback">
              <ContactSupport />
            </Tooltip>
          </Grid>
          <Grid item xs >
            <a
              target="_blank"
              className="twitter-share-button"
              href="https://twitter.com/intent/tweet?text=%40whirledsol%20%23justbreatheio%20Hey%2C"
              data-size="large">Send me a tweet if that still works.</a>
          </Grid>
          <Grid item xs={2} sm={1} justify="flex-end">
            {this.props.installPrompt &&
              <>
                <Tooltip title="App">
                  <a onClick={this.props.toggleDialog.bind(this, "installDialog")}>
                    <SaveAlt />
                  </a>
                </Tooltip>
                <AppDialog
                  open={this.props.installDialog}
                  title="Install App"
                  handleClose={this.props.toggleDialog.bind(this, "installDialog")}
                  content="We respect your privacy and personal space. This includes your phone's too. Instead of installing an app, this website can be saved to your phone's home screen."
                  buttons={() => (
                    <>
                      <Button onClick={this.install}>Bookmark</Button>
                      <Button href="https://www.cnet.com/how-to/adding-one-touch-bookmarks-to-your-androids-home-screen/" target="_blank">Find Out More</Button>
                      <Button onClick={this.props.toggleDialog.bind(this, "installDialog")} autoFocus>Cancel</Button>
                    </>)}
                />
              </>
            }
          </Grid>
        </Grid>

      </div>
    );
  }

  install = () => {
    this.props.installPrompt.prompt();
  }
}
