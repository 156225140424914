import React, { Component } from 'react';
import './BreathingRing.css';
import { BREATHING_MODES, LANGUAGES, BREATHING_TEXT_MAP, DIRECTION, BREATHING_MODES_HALFTIMES } from './constants';

export class BreathingRing extends Component {
  static defaultProps = {
    text: false,
    duration: 12000,
    brightness: 200,
    thickness: 10,
    mode: BREATHING_MODES.normal,
    language: LANGUAGES.none,
  }

  constructor(props){
    super(props);
    this.state = {
      direction: DIRECTION.out,
      durationHalf: this.props.duration/2.0,
      timeout: null
    };
  }

  calculateDurationHalf(){
    return this.props.duration * BREATHING_MODES_HALFTIMES[this.props.mode];
  }

  componentDidMount(){
    this.setState({durationHalf:this.calculateDurationHalf()});
  }

  componentDidUpdate(prevProps){
    if(prevProps.duration !== this.props.duration || prevProps.mode !== this.props.mode){
      this.setState({durationHalf:this.calculateDurationHalf(), direction: null}, ()=>{
        this.onAnimationIteration();
      });
    }
  }

  render() {
    let color = this.brightnessToColor(this.props.brightness);
    return (
      <>
        <svg
          className="breathing-ring-container"
          width="100vw"
          height="100vw">
          <circle
            key={this.props.mode+this.props.duration}
            onAnimationIteration={this.onAnimationIteration}
            className={"breathing-ring animated-breathe-" + this.props.mode}
            style={{ animationDuration: this.props.duration + "ms" }}
            stroke={color}
            strokeWidth={this.props.thickness}
            fill="transparent"
            r="25%"
            cx="50%"
            cy="50%" />
          {(this.props.language in BREATHING_TEXT_MAP) && this.state.direction &&
            <text x="50%" y="50%" fill={color} className="fade-in" fontSize="3vw" textAnchor="middle" dominantBaseline="middle" key={this.props.language+this.state.direction}>
              {BREATHING_TEXT_MAP[this.props.language][this.state.direction]}
            </text>
          }
        </svg>
      </>
    );
  }

  brightnessToColor = (yourNumber) => {
    var hexString = yourNumber.toString(16);
    if (hexString.length % 2) {
      hexString = '0' + hexString;
    }
    return "#" + hexString + hexString + hexString;
  }

  onAnimationIteration = () => {
  console.log('onAnimationIteration', this.state.durationHalf);
    //cear the timeout in case the previous one was cut short
    if(this.state.timeout != null){
      clearTimeout(this.state.timeout);
    }

    //update the direction and callback for the halfway point
    this.setState({
      direction:DIRECTION.out,
      timeout: setTimeout(
        ()=>{
          this.setState({direction: DIRECTION.in});
        },
        this.state.durationHalf)
    });
  }


}