import React, { Component } from 'react';
import { BreathingRing } from './BreathingRing';
import { Settings } from './Settings';
import { Drawer, createMuiTheme, MuiThemeProvider } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import './App.css';
import {LANGUAGES, BREATHING_MODES} from './constants';
export class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      settings: false,
      duration: 12000,
      brightness: 200,
      mode: BREATHING_MODES.normal,
      thickness: 10,
      language:LANGUAGES.none,
      dialogInstall: false
    };

  }

  render() {
    const theme = createMuiTheme({
      palette: {
        type: 'dark',
      },
      overrides:{
        MuiSlider:{
          track:{
            height:"10px"
          },
          thumb:{
            width:"20px",
            height:"20px"
          }
        },
        MuiRadio:{
          root:{
            padding:0,
            paddingBotton:"1rem"
          }
        },
        MuiGrid:{
          item:{
            display:"flex"
          }
        },
        MuiTooltip:{
          tooltip:{
            fontSize:"1rem"
          }
        }
      }
    });

    return (
      <div className="App">
        <MuiThemeProvider theme={theme}>

          <BreathingRing {...this.state} />
          <a className="fixed-bottom"
            onClick={this.toggleSettings}
            onTouchMove={this.toggleSettings}
            alt="Settings"
          >
            <div className="menu-button"><MenuIcon /></div>
          </a>
          <Drawer
            anchor="bottom"
            open={this.state.settings}
            onClose={this.toggleSettings}
          >
            <Settings
            updateSettings={this.updateSettings}
            toggleDialog={this.toggleDialog}
            installPrompt={this.props.installPrompt}
            {...this.state} />
          </Drawer>

        </MuiThemeProvider>
      </div>
    );
  }


  toggleSettings = () => {
    this.setState({ settings: !this.state.settings });
  }

  updateSettings = (property, e, value) => {
    this.setState({[property]: value});
  }
  toggleDialog = (property, e) =>{
    this.setState({[property]: !this.state[property]});
  }


}
